var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-section" }, [
      _c("p", {}, [
        _vm._v(
          " すでに" +
            _vm._s(_vm.WORDS.LINE_ID) +
            "との" +
            _vm._s(_vm.WORDS.LINK) +
            "は完了しています。 "
        ),
      ]),
      _c(
        "div",
        { staticClass: "p-link-line__actions" },
        [_c("ButtonLineLogin", { attrs: { fluid: "", disabled: "" } })],
        1
      ),
      _c("div", { staticClass: "link-line-unlink" }, [
        _c("a", { attrs: { href: _vm.unlinkUrl } }, [
          _vm._v(
            _vm._s(_vm.WORDS.LINK) +
              "を" +
              _vm._s(_vm.WORDS.LINK_CANCEL) +
              "する"
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }