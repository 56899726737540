<template>
  <div>
    <div class="c-section">
      <p class="">
        すでに{{ WORDS.LINE_ID }}との{{ WORDS.LINK }}は完了しています。
      </p>

      <div class="p-link-line__actions">
        <ButtonLineLogin fluid disabled />
      </div>

      <div class="link-line-unlink">
        <a :href="unlinkUrl">{{ WORDS.LINK }}を{{ WORDS.LINK_CANCEL }}する</a>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';
import { SystemDialogMessage } from '@/constants/enums';

export default defineComponent({
  setup: (props, context) => {
    const unlinkUrl = computed(() =>
      context.root.$systemDialog(SystemDialogMessage.LINE_CONNECT_CANCEL)
    );
    return {
      WORDS,
      unlinkUrl
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.link-line-unlink {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 1.6rem;
}
</style>
